.ContactContainer{
    width: 70%;
    display: flex;
    justify-content: center;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-top: 2vh;
}

.ContactContainer2{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
}

.ContactDiv h1{
    font-family: 'BebasNeue', sans-serif;
    text-align: left;
    
}

.ContactDiv p{
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: large;
}

.SocialMediaDiv p{
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: large;
}

.SubscribeDiv h2{
    font-family: 'BebasNeue', sans-serif;
}

.SocialMediaDiv h2{
    font-family: 'BebasNeue', sans-serif;
}

.SubscribeDiv form{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
}

.fieldGroup{
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    align-items: center;
    
}

.indicates-required{
    font-family: 'Montserrat', sans-serif;
    font-size: smaller;
}
.SubscribeDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    


}

.asterisk{
    color: #005d97;
}








.formButton{
    display: block; /* Allows margin auto to work for horizontal centering */
    width: 40%;
    background: linear-gradient(to right, #005d97, #9ddeec 95%);/* Blue background color */
    color: white; /* Text color */
    text-align: center; /* Center text inside the button */
    border: none; /* No borders */
    border-radius: 5px; /* Slight rounding of corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'BebasNeue', sans-serif;
    font-size: x-large;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    margin-top: 1vh;
}

.formButton:hover{
    background: #27292b;
    transform: scale(1.05);
}

.formInput{
    font-size: large;
    text-align: center;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    outline: 0.2vh solid #27292b;
    transition: outline 0.1s ease-out;
    font-family: 'Montserrat', sans-serif;
}

.formInput:hover{
    outline: 0.3vh solid #9bb9d5;
    border: 0.3vh solid transparent;
    transition: outline 0.1s ease-in;
   
  }

  .formInput:focus{
    outline:  0.3vh solid #6fa4bf;
    border: 0.3vh solid transparent;
  }

  .SubscribeSocial{
    display: flex;
    justify-content: center;
    gap: 15%;
  }

  .SocialMediaDiv p{
    text-align: center;
  }

  .socialButton{
    background-color: #27292b;
    border-radius: 50%;
    padding: 0.8vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    cursor: pointer;
    border: none;


  }

  .buttonContent{
    display: flex;
    justify-content: center;
  }
  .buttonContent img{
    height: 2.5vh;

  }

  .socialButton:hover{
    background: #ABACAD;
    transform: scale(1.05);
}

.socials{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.mailChimpImg{

    margin-top: 2vh;
    height: 5vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;

}

.mailChimpImg:hover{
    transform: scale(1.05);
}

/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}



@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1000px){

    .ContactContainer2{
        margin-top: -10vh;
       
       
    }


    .ContactDiv p{
        font-size: x-large;
    }

    .SubscribeSocial{
        flex-direction: column;
        gap: 2vh;
    }

    .SocialMediaDiv h2{
        text-align: center;
    }

    .SocialMediaDiv p{
        font-size: x-large;
    }

    .SubscribeDiv h2{
        text-align: center;
    }
}

@media (max-width: 500px){

    .ContactContainer2{
        margin-top: -20vh;
       
       
    }


    .ContactDiv p{
        font-size: small;
    }

    .SubscribeSocial{
        flex-direction: column;
        gap: 2vh;
    }

    .SocialMediaDiv h2{
        text-align: center;
    }

    .SocialMediaDiv p{
        font-size: small;
    }

    .SubscribeDiv h2{
        text-align: center;
    }
 
    
    
}
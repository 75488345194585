.FaqPageContent{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-top: 2vh;
}

.QuestionCategories{
    display: flex;
    flex-direction: column;
    width: 70%;
   
}

.QuestionCategory{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2vh;
  
}

.CategoryBtn{
    background-color: #27292b;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    height: 5vh;
    z-index: 1;
    cursor: pointer;
    border: none;
    
}

.CategoryBtn:hover{
    background-color: #ABACAD;
}


.CategoryBtnContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    
 

}

.CategoryBtnContainer img{
    height: 3vh;
    margin-top: 1vh;
    margin-right: 2%;
    
}

.CategoryBtnContainer p{
    margin-left: 2%;
    margin-top: 1vh;
    font-size: x-large;
    color: white;
    font-family: "BebasNeue", sans-serif;
    font-weight: bold;
}

.AlignButton{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.QuestionsList{
    
    flex-direction: column;
    border: 0.5vh solid #ABACAD;
    border-top-left-radius: 2vh;
	border-bottom-left-radius: 2vh;
    border-top-right-radius: 2vh;
	border-bottom-right-radius: 2vh;
    margin-top: -5vh;
  
    
}


.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.QuestionAnswerContainer{
    display: flex;
    flex-direction: column;
   
    width: 90%;
    

}

.AnswerContainer p{
    text-align: justify;
    margin-left: 7vh;
    font-size: large;
    font-family: 'Montserrat', sans-serif;
    
}


.AnswerContainer{
    margin-top: -3vh;
   
   
    
}

.QuestionContainer{
    display: flex;
    gap: 2%;
    cursor: pointer;
    transform: translateZ(0) scale(1);
    transition:  transform .2s;
    align-items: center;
    
  
    
}

.QuestionContainer p {
    font-family: 'BebasNeue', sans-serif;
    font-weight: bold;
    font-size: larger;
}

.QuestionContainer img {
   
    cursor: pointer;
 
}

.QuestionContainer img{
    height: 2vh;
    margin-left: 2%;
}

.QuestionContainer:hover{
    transform: scale(1.01);
   
}

.QuestionContainer:hover img{
/*    filter: brightness(0) saturate(100%) invert(95%) sepia(12%) saturate(7450%) hue-rotate(178deg) brightness(102%) contrast(67%);
 */
 filter: invert(19%) sepia(74%) saturate(3094%) hue-rotate(187deg) brightness(92%) contrast(101%);
}

.QuestionContainer:hover p{
    color:#005d97;
    transform: scale(1.05);
}

.Container{
    margin-top: 5vh;
}

/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}



@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1500px)
{

    .CategoryBtnContainer p{
        margin-left: 2%;
        margin-top: 1vh;
        font-size: large;
       
    }

    .CategoryBtnContainer img{
        height: 2.5vh;
        margin-top: 1vh;
        margin-right: 2%;
        
    }

}
@media (max-width: 1000px){
    .FaqPageContent{
        margin-top: -10vh;
     }
     .QuestionCategories{
        width: 90%;
     }

     .AnswerContainer p{
        font-size: large;
        margin-left: 3.8vh;
        
     }
}

@media (max-width: 500px){
     .FaqPageContent{
        margin-top: -20vh;
     }

     .QuestionCategories{
        width: 90%;
     }

     .CategoryBtnContainer p{
        font-size: large;
     }

     .QuestionContainer p{
        font-size: large;
     }

     .QuestionContainer{
        gap: 2vh;
     }

     .AnswerContainer p{
        font-size: small;
        margin-left: 0;
        
     }

     
    
    
}
.FooterContainer{
    display: flex;
    width:100%;
    background-color: #27292b;
    position: relative; 
    bottom: 0; 
    left: 0; 
    z-index: 10;
    height: 35vh;
    justify-content: center;
}

.FooterTextContainer p {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}


.FooterContent{
    width: 80%;
   
}
.FooterTextContainer{
    display: flex;
    gap: 2vh;
    flex-direction: column;
    align-items: center;
    
}


.NgiImage {
    height: 7vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;

}

.NgiImage:hover{
    transform: scale(1.05);
}

.EuImage {
    height: 12vh;
    
}

/*@media (max-width: 1520px){
}



@media (max-width: 1000px){
}

@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/
@media (max-width: 1000px){

    .FooterTextContainer p{
        font-size: large;
    }
}

@media (max-width: 500px){

    .FooterTextContainer p{
        font-size: small;
    }

    .NgiImage{
        height: 5vh;
    }

    .EuImage{
        height: 7vh;
    }

    .FooterContainer{
        height: 55vh;
    }
            
}


  
.MenuBarContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 50vh;
}

.carousel{
    overflow: hidden;
    white-space: no-wrap;
   /* background: linear-gradient(to right, #005d97, #9ddeec);*/
   background: #e2e3e3ff;

}
.TryAppContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 190%;
    height: 8vh;
    animation: scrollText 15s linear infinite;

   
    
}

@keyframes scrollText {
    from {
      transform: translateX(0%); 
    }
    to {
      transform: translateX(-50%); 
    }
  }

.TryAppContainer:hover .bubble{
    transform: scale(1.04);
}
.bubble{
  /*  color: white;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
  /*  animation: typing 3.5s steps(40, end) infinite, blink-caret 0.75s step-end infinite; */
 /*   font-size: xx-large;*/
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    height: 10vh;
    margin-top: 2.5vh;
    
    
}



@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    70% { border-color: black; } 
}

.MenuBar{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #27292b;
    z-index: 1000;
    height: 4vh;
  

   
}

.fixed-menubar{
    position: fixed;
    
}



button.MenuButton{
    font-size: xx-large;
    font-family: 'BebasNeue', sans-serif;
    font-weight: bold;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform .2s;

}

.MenuButton:hover{
 /*  color: #ABACAD;*/
  transform: scale(1.08);
}

.MenuButton.focused{
    color:#ABACAD;
}

.ButtonLink{
    text-decoration: none;
    text-emphasis: none;
}
.DivLink{
    text-decoration: none;
    width: 100%;
   
}

.HeaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: hidden;

}

.BurgerBar{
    display: none;
}

.HeaderImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fillerText{
    font-family: 'Montserrat', sans-serif;
}

.BurgerMenuBar{
    display: none;
}


/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}


@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1500px)
{
    .tryAppMessage{
        font-size: x-large;
    }
    .MenuBar{
        height: 5vh;
    }
}
@media (max-width: 1000px){

    .TryAppContainer{
        height: 5vh;
    }

    .tryAppMessage{
        font-size: large;
    }

    .bubble{
        margin-top: 3.5vh;
    }

  
}


@media (max-width: 500px){

    .tryAppMessage{
        font-size: 1.05vh;
    }

    .TryAppContainer{
        height: 4vh;
    }

    button.MenuButton{
        font-size: large;
    }

    .MenuBar{
        display: none;
    }

    .BurgerBar{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        background-color: #27292b;
        z-index: 1000;
        height: 4vh;
        position: relative;
        
      
    
       
    }

    .fixed-burgermenu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #27292b;
        z-index: 1000;
        height: 35vh;
        width: vh;
        position: fixed;
        left: 0;
        top: 4vh;
        gap: 1vh;
       
    }

   

    .BurgerBar img{
        height: 2.5vh;
        margin-left: 1vh;

    }

    .fixed-burgerbar{
        position: fixed;
    }
    .BurgerMenuBar{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #27292b;
        z-index: 1000;
        height: 35vh;
        width: vh;
        position: fixed;
        left: 0;
        top: 23.5vh;
        gap: 1vh;
    

      
    
       
    }

 

    .burgerButton{
        cursor: pointer;
    }

    .burgerButton:hover{
        filter: brightness(0) saturate(100%) invert(85%) sepia(0%) saturate(5460%) hue-rotate(309deg) brightness(83%) contrast(86%);

    }

    .bubble img{
        height: 4vh;
    }

    .bubble{
        margin-top: 6vh;
    }


   
            
}
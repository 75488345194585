.MemberPageDiv{
    width: 70%;
}

.MemberPageContent{
    display: flex;
    justify-content: center;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-bottom: 3vh;
}

.MemberPageDiv h1, h2{
    font-family: 'Montserrat', sans-serif;
    text-align: justify;

}

.MemberLayout{
    display: flex;
    gap: 20vh;
    justify-content: center;
    align-items: center;
    
    
}

.MemberLayout p{
    font-size: large;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    
}

.MemberLayout img{
    height: 30vh;
}

.LinkedInButton{
    background-color: #27292b;
    border: none;
    padding: 01vh;
    border-radius: 50%;
    height: 20%;
    transform: translateZ(0) scale(1);
    transition: transform ease .2s;
    cursor: pointer;
}

.LinkedInButton img{
    height: 2.5vh;
}

.memberLinkedIn
{
    display: flex;
    gap: 1vh;
    align-items: center;
    
}

.LinkedInButton:hover{
    transform: scale(1.1);
    background-color: #9bb9d5;

}



.GoBackLink h1{
    color: #005d97;
    font-family: 'BebasNeue', sans-serif;
}


/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}



@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1000px){
   
    .MemberPageDiv h2{
       
        margin-top: -2vh;
    }

    .MemberLayout{
        flex-direction: column;
        gap: 1vh;
    }

    .MemberLayout p{
        font-size: x-large;
    }

    .MemberPageContent{
        margin-top: -10vh;
    }
}

@media (max-width: 500px){

    .memberLinkedIn h1{
        font-size: x-large;
    }

    .MemberPageDiv h2{
        font-size: large;
        margin-top: -2vh;
    }

    .MemberLayout{
        flex-direction: column;
        gap: 1vh;
    }

    .MemberLayout p{
        font-size: small;
    }

    .MemberPageContent{
        margin-top: -20vh;
    }

          
}
.PublicationsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.PublicationsList{
    width: 70%;
}
.PublicationItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
}

.PublicationItem a{
    text-decoration: underline;
    color: black;

}
.PublicationItem p{
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: large;
   

}
.PublicationItem img{
    height: 4vh;
}


/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}



@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1000px){
    .PublicationsContainer{
        margin-top: -10vh;
    }

    .PublicationItem img{
        height: 3vh;
    }
    .PublicationItem{
        flex-direction: column;
    }

    .PublicationItem p{
        font-size: x-large;
    }

}

@media (max-width: 500px){

    .PublicationItem p{
        font-size: small;
    }

    .PublicationItem{
        flex-direction: column;
    }

    .PublicationItem img{
        height: 4vh;
    }

    .PublicationsContainer{
        margin-top: -20vh;
    }

            
}
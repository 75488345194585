.TeamContainer2{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    margin-top: 2vh;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.TeamContainer{
    width: 80%;
}
.TeamContainer h1{
    font-family: 'BebasNeue', sans-serif;
    text-align: left;
}

.TeamContainer p{
    font-family: 'Montserrat', sans-serif;
    font-size: large;
    text-align: justify;
}

.TeamGallery{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2vh;
    row-gap: 1.5vh;
    width: 80%;
    
}

.imageContainer{
    position: relative;
    transform: translateZ(0) scale(1);
    transition: filter 0.3s ease, transform .2s;
    cursor: pointer;
}
.imageContainer img{
    height: 40vh;
    filter: grayscale(100%);
    

    
}

.imageContainer:hover img{
    filter: grayscale(0%);
  
    
}



.GalleryContainer{
    display: flex;
    justify-content: center;
   
}

.TeamGallery{
    display: flex;
    justify-content: center;
}
   

.imageText h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-left: 1vh;
}

.imageText h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-top: -2vh;
    margin-left: 1vh;
}

.imageText{
 
    z-index: 1000;
    color: white;
    position: absolute;
    margin-top: -12vh;
    margin-left: 1vh;
    transform: translateZ(0) scale(1);
    transition:  transform .2s;
    cursor: pointer;
}

.imageContainer:hover{
    transform: scale(1.02);
   

}


.description{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #005d97, #9ddeec);
    height: 100%;
    z-index: 1000;
   
    
    

}

.overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, transparent 2%, rgba(0, 0, 0, 0.7));
    height: 15vh;
    z-index: 1000;
    
    
   
}




.description.closed{
    visibility: hidden;
    opacity: 0;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;

}


.description h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    color: white;
    margin-left: 2vh;
}

.description h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-top: -2vh;
    color: white;
    margin-left: 2vh;
}

.description-container{
    display: flex;
    justify-content: center;
    margin-top: 6vh;
    width: 100%;
    

}

.description-text{
    width: 90%;
}
.description-text p{
    color: white;
    font-family: "Montserrat", sans-serif;
    justify-content: justify;
    font-size: large;
}

.description-footer{
    position: fixed;
    margin-top: 2vh;;
}

.viewMore{
    text-decoration: none;
    color:#27292b;
}

.viewMore:hover{
    color:#b9e1f5;
    text-decoration: underline;
   
}

.LinkedInButton.shrunk{
    background-color:  #005d97;
    margin-top: 0.5vh;

}

.LinkedInButton.shrunk img{
    height: 2.5vh;
    
}

.LinkedInButton.shrunk:hover{
    background-color: #27292b;

}


/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}


@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1500px)
{
    .image-h1{
        font-size: x-large;
        margin-top: 3vh;
    }
    
    .image-h2{
        font-size: large;
    }

    .description-text p{
        font-size: small;
        margin-top: -2vh;
    }

}

@media (max-width: 1000px){
    .TeamContainer2{
        margin-top: -10vh;
    }

    .description-text p{
        font-size: x-large;
    }

    .TeamContainer p{
        font-size: x-large;
    }

   
}


@media (max-width: 500px){

.TeamContainer2{
    margin-top: -20vh;
}

.TeamContainer p{
    font-size: small;
}

.imageContainer img{
    height: 30vh;
    width: 30vh;
}

.TeamGallery{
    justify-content: center;
}

.imageContainer{
    height: 30vh;
    width: 30vh;
}

.image-h1{
    font-size: large;
    margin-top: 5vh;
}

.image-h2{
    font-size: small;
}

.memberLinkedIn h1{
    font-size: large;
}



.LinkedInButton.shrunk{
    height: 3.7vh;
    width: 3.7vh;
}

.LinkedInButton.shrunk img{
    height: 2vh;
    width: 1.8vh;

}

.description-text p{
    font-size: x-small;
}
            
}
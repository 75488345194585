.BlogContainer{
    display: flex;
    justify-content: center;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    margin-top: 2vh;
}

.PostList{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 7vh;
    margin-bottom: 3vh;
    
}

.PostContainer{
    display: flex;
    justify-content: center; 
    border: 0.5vh solid #ABACAD;
    border-top-left-radius: 2vh;
	border-bottom-left-radius: 2vh;
    border-top-right-radius: 2vh;
	border-bottom-right-radius: 2vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    background-color: white;
}

.PostContainer:hover{
    transform: scale(1.02);
}

.PostContainer:hover h1{
    color:#005d97;
}

.PostContainer:hover .PostFooter h2{
    color:#005d97;
}
.PostContent{
    display: flex;
    width: 90%;
    justify-content: space-between;
    gap: 5%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    
    
  
}

.PostImageContainer{
    display: flex;
    align-items: center;
    border: 0.3vh solid #ABACAD;
    aspect-ratio: 1;
   height: 40vh;
   margin-top: 1vh;
   margin-bottom: 1vh;
}



.PostImageContainer img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
}

.fade {
    opacity: 0; 
}

.show {
    opacity: 1; 
}


.PostFooter{
    display: flex;
    justify-content: space-between;

}

.PostTextContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.PostTextContent h1{
    font-size: x-large;
    text-align: justify;
    font-family: 'BebasNeue', sans-serif;
   
}

.TitleLink{
    text-decoration: none;
    color: #27292b;
}



.PostTextContent p{
    
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}


.PostFooter h2{
    font-size: larger;
    font-family: 'BebasNeue', sans-serif;
    font-weight: 500;
    text-decoration: underline;
}

/*@media (max-width: 1520px){
}


@media (max-width: 1000px){
}

@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1000px){
    .PostContent{
        flex-direction: column;
        align-items: center;
        gap: 1vh;
      
       
      }
      
      .PostTextContent{
      
        font-size: large;
        
      }
      
      .PostImageContainer{
        width: 25vh;
        height: 25vh;
        
      }
  
      .BlogContainer{
          margin-top: -10vh;
      }
      
}


@media (max-width: 500px){

    .PostContent{
      flex-direction: column;
      align-items: center;
      gap: 1vh;
    
     
    }
    
    .PostTextContent{
    
      font-size: small;
      
    }
    
    .PostImageContainer{
      width: 25vh;
      height: 25vh;
      
    }

    .BlogContainer{
        margin-top: -20vh;
    }
    
    
}



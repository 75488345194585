

.ContentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    overflow: hidden;
    
    
}

.box_link{
color: white;
text-decoration: underline;
}

.boxes-container{
    display: flex;
    width: 80%;
    
  
    justify-content: center;
    gap: 10%;
}

.home-box-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    background: linear-gradient(to right, #27292b 55%, #757677);
    width: 35%;
}

.home-box-right{
    display: flex;
    flex-direction: column;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    align-items: center;
    background: linear-gradient(to right, #177505, #004306 35%);
    width: 35%;
}

.home-box-left h1{
    color: white;
    font-family: 'BebasNeue', sans-serif;
    text-align: center;
}

.boxes-container p {
    color: white;
    font-family: 'Montserrat', sans-serif;
    width: 90%;
    text-align: justify;
}
.home-box-right h1{
    color: white;
    font-family: 'BebasNeue', sans-serif;
    text-align: center;
}

.ContentContainer p{
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: large;
  
}

.TextContainer{
    display: flex;
    flex-direction: column;
    width: 70%;
}

.logoContainer img{
    height: 20vh;
}

.HomeSlideShowDiv{
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    margin-bottom: 5vh;
    justify-content: center;
    align-items: center;
  
}

.HomeSlideShow{
    display: flex;
    
    justify-content: center;
    background: linear-gradient(to right, #005d97, #9ddeec);
    align-items: center;
}

.HomeImageContainer{
    height: 45vh;
    width: 80vh;
    margin-top: 2vh;
   
 
}

.StepsContainer h1{
    font-family: 'BebasNeue', sans-serif;
    color:white;
    font-size: xx-large
}

.StepsContainer h2{
    font-family: 'Montserrat', sans-serif;
    color:white;
    font-size: x-large;
    font-weight: 400;

}


.HomeImageContainer img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
    box-shadow: 1vh 1vh 3vh #27292b, /* Outer shadow */
    -2vh -1vh 3vh #27292b;
}

/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}





@media (min-height: 501px) and (max-height: 1000px){
}*/



@media (max-width: 1000px){
    .ContentContainer{
        margin-top: -10vh;
    }

    .TextContainer p{
        font-size: x-large;
    }

    .StepsContainer h1{
        font-size: xx-large;
    }

    .HomeImageContainer{
        width: 90%;
        height: 40vh;
    }

    .boxes-container{
        display: flex;
        width: 80%;
        flex-direction: column;
        align-items: center;
    
        gap: 2vh;
      
    }
    
    .home-box-left{
        width: 100%;
    }
    
    .home-box-right{
        width: 100%;
    }
}

@media (max-width: 500px){

    
.HomeImageContainer{
    height: 30vh;
    width: 85%;
    margin-top: 2vh;
   
 
}

.StepsContainer h1{
    font-size: x-large;
}

.StepsContainer h2{
    font-size: smaller;
}

.TextContainer p{
    font-size: small;
}
    
.ContentContainer{
    margin-top: -20vh;
}

.boxes-container{
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
   
    gap: 2vh;
  
}

.home-box-left{
    width: 100%;
}

.home-box-right{
    width: 100%;
}
}
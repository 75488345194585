.formClass{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.SignInContent{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
  
}

.login-form h1{
    font-family: 'BebasNeue', sans-serif;
}

.center-button {
    /* Allows margin auto to work for horizontal centering */
    width: 50%; /* Small width for the button */
    background: linear-gradient(to right, #005d97, #9ddeec 95%); /* Blue background color */
    color: white; /* Text color */
    text-align: center; /* Center text inside the button */
    border: none; /* No borders */
    border-radius: 5px; /* Slight rounding of corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-family: 'BebasNeue', sans-serif;
    font-size: x-large;
    border-top-left-radius: 3vh;
	border-bottom-left-radius: 3vh;
    border-top-right-radius: 3vh;
	border-bottom-right-radius: 3vh;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    margin-top: 7%;

}

.center-button:hover{
    background: #27292b;
    transform: scale(1.05);
   

}
.SinglePostContent{
    display: flex;
    justify-content: center;
    margin-bottom: 3vh;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.SinglePostText{
    width: 70%;
    
}

.SinglePostText h1{
    font-family: 'BebasNeue', sans-serif;
    text-align: justify;
}

.SinglePostText p{
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
}

.SinglePostInfo{
    display: flex;
    justify-content: space-between;
}

.SinglePostInfo h2{
    font-family: 'BebasNeue', sans-serif;
    font-weight: 500;
    text-decoration: underline;
}
.SinglePostHeader{
    display: flex;
    flex-direction: column;
    
}


.PostBody img{
    width: 70%;
    border: 0.3vh solid #9bb9d5;
    margin-bottom: 1vh;
    margin-top: 1vh;
   
    
}


.PostBody h3{
    font-family: 'BebasNeue', sans-serif;
    font-size: x-large;
    text-align: justify;
}

.BodyImageDiv{
    display: flex;
    justify-content: center;
}

a{
    text-decoration: none;
    color: #005d97;
 
}


#verifailogo{
    border: none;

}

.GoBackLink{
    color: #005d97;
    font-family: 'BebasNeue', sans-serif;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
  
}

.GoBackLink h1{
    font-weight: 100;
    
}

.GoBackLink:hover{
    color:#9bb9d5;
    transform: scale(1.05);
}
.GoBackDiv{
    display: flex;
    justify-content: space-between;
}

/*@media (max-width: 1520px){
}

@media (max-width: 1000px){
}


@media (max-height: 500px){
}

@media (min-height: 501px) and (max-height: 1000px){
}*/

@media (max-width: 1000px){

    
    .SinglePostContent{
        margin-top: -10vh;
    }

    .GoBackDiv{
        flex-direction: column;
    }

    .PostBody img{
        width: 90%;
    }

    .SinglePostText p{
        text-align: justify;
        font-family: 'Montserrat', sans-serif;
        font-size: large;
    }
    
}

@media (max-width: 500px){

 
    .SinglePostContent{
        margin-top: -20vh;
    }

    .GoBackDiv{
        flex-direction: column;
    }

    .PostBody img{
        width: 90%;
    }
    
}